<link href="/libs/ion-rangeslider/ion.rangeSlider.min.css" rel="stylesheet" type="text/css" />

<script>
import { ref, watch } from "vue";
import useVuelidate from "@vuelidate/core";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
//import bodysvg from "@/components/bodysvg";
import DropZone from "@/components/widgets/dropZone";
import appConfig from "../../../app.config";
import { useAuth0 } from "@auth0/auth0-vue";
import { postWorkout, getTypes, getCategories } from "@/services/workout.service";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';


export default {
  setup() {
    const { getAccessTokenSilently } = useAuth0();
    let files = ref([]);
    let dropzoneFile = ref("");
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      files.value.push(dropzoneFile.value);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      files.value.push(dropzoneFile.value);
    };
    watch(
      () => [...files.value],
      (currentValue) => {
        return currentValue;
      }
    );
    return { dropzoneFile, files, drop, selectedFile, v$: useVuelidate(), getAccessTokenSilently };
  },
  page: {
    title: "Starter",
    meta: [{ name: "description", content: appConfig.description }],
  },
methods: {
  async getData(){
    try {
        const { getAccessTokenSilently } = useAuth0();
        const accessToken = await getAccessTokenSilently();
        const { types, typeserror } = await getTypes(accessToken);
        const { categories, categorieserror } = await getCategories(accessToken);
        if (types) {
            this.types = types;
        }
        if (typeserror) {
            this.typeserror = [];
        }
        if (categories) {
            this.categories = categories;
        }
        if (categorieserror) {
            this.categories = [];
        }
    } catch (e) {
       console.log('failure' + e)
    }
},
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },
    addYtlink(url) {
      this.ytlinks.push(url);
      this.$refs.video.value = "";
    },
    async addWorkout(){
        console.log(this.files);
        this.name = this.$refs.name.value
        this.type = this.$refs.type.value
        this.category = this.$refs.category.value
        this.description = this.$refs.description.value
        this.access = this.$refs.access.value
        let formData = new FormData();
        formData.append('name', this.$refs.name.value);
        formData.append('type', this.$refs.type.value);
        formData.append('category', this.$refs.category.value);
        formData.append('description', this.$refs.description.getHTML());
        formData.append('ytlinks', this.ytlinks);
        formData.append('access', this.$refs.access.value);
        formData.append('files', this.files);

        const accessToken = await this.getAccessTokenSilently();
        const { data, error } = await postWorkout(accessToken,formData);
        if (data) {
            this.myAddModal.hide()
        }
        if (error) {
            console.log(error);
        }
      },
},
  mounted() {
    this.getData();
  },
  data() {
    return {
        name: '',
        type: '',
        category: '',
        types: [],
        categories: [],
        description: '',
        ytlinks: [],
        access: '',
      title: "Starter",
      items: [
        {
          text: "Pages",
          href: "/",
        },
        {
          text: "Starter",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    DropZone,
    QuillEditor,
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title mb-4">Create a new workout</h4>
                <form id="exerciseForm" action="/api/workout/create" enctype="multipart/form-data" method="POST">
                    <div class="row mb-4">
                        <label for="exercisename" class="col-sm-3 col-form-label">Name</label>
                        <div class="col-sm-5">
                          <input type="text" ref="name" class="form-control" name="exercisename" id="exercisename">
                        </div>
                    </div>
                    <div class="row mb-4">
                        <label for="exercisetype" class="col-sm-3 col-form-label">Type</label>
                        <div class="col-sm-5">
                            <select ref="type" class="form-control select2-search-disable" name="exercisetype" id="exercisetype">
                                <option></option>
                                <option v-for="(type) in types" :value="type._id" :key="type.id">
                                  {{type.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <label for="exercisetype" class="col-sm-3 col-form-label">Categori</label>
                        <div class="col-sm-5">
                            <select ref="category" class="form-control select2-search-disable" name="exercisetype" id="exercisetype">
                                <option></option>
                                <option v-for="(category) in categories" :value="category._id" :key="category.id">
                                  {{category.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <label for="exercisedescription" class="col-sm-3 col-form-label">Description</label>
                        <div class="col-sm-9">
                          <QuillEditor ref="description" style="height: 250px" theme="snow" />
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <label for="exercisevideolinks" class="col-sm-3 col-form-label">Youtube link</label>
                        <div class="col-sm-6">
                          <input type="text" ref="video" id="Ytlink" placeholder="Enter embedded link, like: https://www.youtube.com/embed/ABC123XYZ" class="form-control exercisevideolinks" name="YtvideoLinks">
                        </div>
                        <div class="col-1">
                            <button type="submit" id="addYtLink" @click.prevent="addYtlink(this.$refs.video.value)" class="btn btn-primary w-md">Add</button>
                        </div>
                    </div>
                    <div class="row mb-4 align-items-center">
                        <label for="linklist" class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-8" id="linklist">
                            <li v-for="url in ytlinks" :key="url.id">
                                {{ url }}
                            </li>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <label for="exerciseaccess" class="col-sm-3 col-form-label">Access</label>
                        <div class="col-sm-9">
                            <div class="form-check">
                                <input ref="access" class="form-check-input" id="0" type="radio" value="0" name="exerciseaccess" checked>
                                <label class="form-check-label" for="formRadios1">
                                    Private
                                </label>
                            </div>
                            <div class="form-check">
                                <input ref="access" class="form-check-input" id="1" type="radio" value="1" name="exerciseaccess">
                                <label class="form-check-label" for="formRadios2">
                                    Friends
                                </label>
                            </div>
                            <div class="form-check">
                                <input ref="access" class="form-check-input" id="2" type="radio" value="2" name="exerciseaccess">
                                <label class="form-check-label" for="formRadios2">
                                    Public <i>*Will require site content approval </i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-sm-2">
                            <div>
                                <button type="button" id="ButtonLoading" class="btn btn-primary w-md" style="display:none;">
                                    <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Saving
                                </button>
                                <button type="submit" id="createExerciseBtn" @click.prevent="addWorkout" class="btn btn-success w-md">Create</button>
                                <br /><br />
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                      <DropZone @drop.prevent="drop" @change="selectedFile" />
                        <ul class="list-unstyled mb-0" id="dropzone-preview">
                          <div class="border rounded" v-for="(file, index) of files" :key="index">
                            <div class="d-flex p-2">
                              <div class="flex-grow-1">
                                <div class="pt-1">
                                  <h5 class="fs-14 mb-1" data-dz-name="">
                                    {{ file.name }}
                                  </h5>
                                  <p class="fs-13 text-muted mb-0" data-dz-size="">
                                    <strong>{{ file.size / 1024 }}</strong> KB
                                  </p>
                                  <strong class="error text-danger" data-dz-errormessage=""></strong>
                                </div>
                              </div>
                              <div class="flex-shrink-0 ms-3">
                                <button data-dz-remove="" class="btn btn-sm btn-danger" @click.prevent="deleteRecord">Delete</button>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </div> <!-- end col -->
                  </form>
                </div>
                <!-- end card body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
          <!-- end row -->
        <div class="row">
      </div> <!-- end row -->
    </Layout>
</template>